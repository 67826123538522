const initState = {
    getAllUserDetails: [],
}
export const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_USER_DETAILS":
            return { ...state, getAllUserDetails: action?.payload }
        default:
            return state

    }
}