const initState = {
    getAllSeriesData: [],
    getAllCatrgory: [],
    getEpisodeById: [],
    getEpisodeByProgress: 0,
    loader: false,
    authors: [],
    showPublished: true,
    category: 'ALL',
    subject: 'ALL',
}
export const UploadAudioReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_CATEGORY':
            return { ...state, category: action?.payload }
        case 'UPDATE_SUBJECT':
            return { ...state, subject: action?.payload }
        case 'GET_ALL_SERIES_DATA':
            return { ...state, getAllSeriesData: action?.payload }
        case 'GET_ALL_CATEGORY':
            return { ...state, getAllCatrgory: action?.payload }
        case 'GET_EPISODE_BY_SERIES_ID':
            return { ...state, getEpisodeById: action?.payload }
        case 'GET_EPISODE_BY_PROGRESS':
            return { ...state, getEpisodeByProgress: action?.payload }
        case 'SET_LOADER':
            return { ...state, loader: action?.payload }
        case 'FETCH_AUTHOR':
            return { ...state, authors: action?.payload }
        case 'TOGGLE_PUBLISH_DRAFT_SERIES':
            return { ...state, showPublished: action?.payload }
        default:
            return state
    }
}