const initState = {
    getAuthorData: [],
    getSeriesForAuthor: [],
    loader: false,
    series: []

}
export const AuthorReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_AUTHOR":
            return { ...state, getAuthorData: action?.payload }
        case "DELETE_AUTHOR":
            return { ...state, deleteAuthor: action?.payload }
        case "SET_LOADER":
            return { ...state, loader: action?.payload }
        case "GET_SERIES_FOR_AUTHOR":
            return { ...state, getSeriesForAuthor: action?.payload }
        case "GET_SERIES_IN_AUTHOR":
            return { ...state, series: action?.payload }
        default:
            return state

    }
}