const initState = {
    headers: [],
    popups: [],
    flatBanners: [],
    seriesGroups: []
}
export const HeaderBarReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_HEADERS":
            return { ...state, headers: action?.payload }
        case "GET_POPUPS": 
            return { ...state, popups: action?.payload }
        case "GET_FLAT_BANNER": 
            return { ...state, flatBanners: action?.payload }
        case "SERIES_GROUPING": 
            return { ...state, seriesGroups: action?.payload }
        default:
            return state

    }
}