const initState = {
    getCategoryData: [],
    getSeriesForCategory: [],
    loader: false,
    series: []
}

export const CategoryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY':
            return { ...state, getCategoryData: action?.payload }
        case 'DELETE_CATEGORY':
            return { ...state, deleteAuthor: action?.payload }
        case 'SET_LOADER':
            return { ...state, loader: action?.payload }
        case 'GET_SERIES_FOR_CATEGORY':
            return { ...state, getSeriesForCategory: action?.payload }
        case 'GET_SERIES_IN_CATEGORY':
            return { ...state, series: action?.payload }
        default:
            return state
    }
}
