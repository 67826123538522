import jwtDefaultConfig from './jwtDefaultConfig'
import { toast } from 'react-toastify'
// import { axiosInstance } from '../../../ApiClient'
import Axios from 'axios';

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASENAME,
})
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []
  //toast css
  toastCSS = () => {
    return {
      position: 'top-center',
      autoClose: 3000,
      icon: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  }

  constructor (jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
  }

  onAccessTokenFetched (accessToken) {
    this.subscribers = this.subscribers.filter(
      callback => callback(accessToken))
  }

  addSubscriber (callback) {
    this.subscribers.push(callback)
  }

  getToken () {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken () {
    let refreshToken = localStorage.getItem(
      this.jwtConfig.storageRefreshTokenKeyName)
    return refreshToken
  }

  removeTokens () {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.userDataKeyName)
  }

  setToken (value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken (value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  async login (...args) {
    console.log('...args',...args);
    try {
      let loginIn = await axiosInstance.post(this.jwtConfig.loginEndpoint, ...args)
      console.log('login',loginIn); 
      this.setToken(loginIn.data.tokens.access.token)
      this.setRefreshToken(loginIn.data.tokens.refresh.token)
      return loginIn
    } catch (err) {
      toast.error(err.response.data.message.replace(/\\/g, ''), this.toastCSS())
    }

  }

  register (...args) {
    return axiosInstance.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken () {
    return axiosInstance.post(
      this.jwtConfig.refreshEndpoint, {
        token: this.getRefreshToken(),
    })
  }
} 