const initState = {
    getUserData: [],
    // getAllCatrgory: [],
    // getEpisodeById: [],
    getLiveUserData: [],
    loader: false,
    // authors: [],
}
export const UserPermissionReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_USER":
            return { ...state, getUserData: action?.payload }
        case "GET_LIVE_USER_DATA":
            return { ...state, getLiveUserData: action?.payload }
        case "SET_LOADER":
            return { ...state, loader: action?.payload }
        default:
            return state
    }
}