const initState = {
    getContactUsData: [],
    loader: false

}
export const ContactUsReducer = (state = initState, action) => {
    switch (action.type) {
        // case "GET_BANNER":
        //     return { ...state, getBannerData: action?.payload }
        // case "DELETE_BANNER":
        //     return { ...state, deleteBanner: action?.payload }

        case "CREATE_NEW_CONTACT_US":
            return { ...state, createNewContactUs: action?.payload }

        // case "EDIT_BANNER":
        //     return { ...state, editBanner: action?.payload }
        case "SET_LOADER":
            return { ...state, loader: action?.payload }
        // case "GET_SERIES_IN_BANNER":
        //     return { ...state, series: action?.payload }
        default:
            return state

    }
}