// // ** Reducers Imports
// import navbar from '../navbar'
// import layout from '../layout'
// import auth from '../authentication'
// import todo from '@src/views/apps/todo/store'
// import chat from '@src/views/apps/chat/store'
// import users from '@src/views/apps/user/store'
// import email from '@src/views/apps/email/store'
// import invoice from '@src/views/apps/invoice/store'
// import calendar from '@src/views/calendar/store'
// import ecommerce from '@src/views/apps/ecommerce/store'
// import dataTables from '@src/views/tables/data-tables/store'
// import permissions from '@src/views/apps/roles-permissions/store'
import auth from '../authentication'
// const rootReducer = {
//     auth,
//     todo,
//     chat,
//     email,
//     users,
//     navbar,
//     layout,
//     invoice,
//     calendar,
//     ecommerce,
//     dataTables,
//     permissions
// }

// export default rootReducer
import { combineReducers } from 'redux'
import { DashboardReducer } from './Dashboard'
import { UploadAudioReducer } from './UploadAudio'
import layout from '../layout'
import { BannersReducer } from './banner'
import { AuthorReducer } from './author'
import { CategoryReducer } from './category'
import { UserPermissionReducer } from './permission'
import { UserReducer } from './User'
import { ContactUsReducer } from './contactUs'
import { HeaderBarReducer } from "./HeaderBar"

const rootReducer = combineReducers({
    DashboardReducer: DashboardReducer,
    UploadAudioReducer: UploadAudioReducer,
    auth,
    layout,
    BannersReducer: BannersReducer,
    AuthorReducer: AuthorReducer,
    CategoryReducer: CategoryReducer,
    UserPermissionReducer: UserPermissionReducer,
    UserReducer: UserReducer,
    ContactUsReducer: ContactUsReducer,
    HeaderBarReducer,
})

export default rootReducer
