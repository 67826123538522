const initState = {
    getDashboardData: [],
    getDashboardDataTotal : []

}
export const DashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_DASHBOARD_DATA":
            return { ...state, getDashboardData: action?.payload }
        case "GET_DASHBOARD_DATA_ALL":
            return { ...state, getDashboardDataTotal: action?.payload }
        default:
            return state

    }
}